.contacto{
    padding: 50px; 
    max-width: 1300px;
    margin: 0px auto;
    position: relative;
    z-index: 100;
}

.contacto_max hr{
    width: 30%;
    margin: 50px 0;
    background: #29ABE2;
    border: 1px solid #29ABE2;
}

.contacto_contenedor{
    width: 100%;
    display: flex;
}

.contacto_fondo{
    width: 50%;
    padding-right: 20px;
    text-align: left;
}

.contacto_fondo p {
    width: 60%;
}

.contacto_fondo > :first-child {
    font-size: 35px;
    margin-bottom: 10px;
}


.redes_contenedor{
    width: 50%;
    display: flex;
    align-items: flex-end;
    margin-top: 50px;
}


.redes_contenedor .redes{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.redes > p{
    font-size: 13px;
    color: #29ABE2;
    margin-bottom: 15px;
}

.red{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.red img{
    max-height: 35px;
    cursor: pointer;
    padding: 0;
    padding-right: 20px;
}

.red p{
    width: 60%;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
}

/* COLORES PARA CADA PARRAFO */

.black{
    color: #000000;
}

@media only screen and (max-width: 768px){
    .contacto_contenedor {
        display: block;
    }

    .redes_contenedor {
        width: 100%;
    }

    .redes_contenedor .redes {
        width: 50%;
    }

}

@media only screen and (max-width: 500px){
    .contacto {
        height: 715px;
    }

    .contacto_fondo {
        width: 100%;
        padding-right: 0px;
        text-align: left;
    }

    .redes_contenedor {
        display: block;
    }

    .redes_contenedor .redes {
        width: 50%;
        float: left;
        margin: 10px 0px 10px 0px;
    }

    .red img {
        max-height: 35px;
        cursor: pointer;
        padding: 10px 10px 0 0;
        padding-right: 10px;
    }
}
