.organizadores{
    width: 100%;
    position: relative;
    z-index: 10;
}

.max_org{
    max-width: 1300px;
    margin: 0px auto;
}

.title_org{
    padding: 150px 0 50px 0;
    text-align: center;
    width: 25%;
    font-size: 25px;
    margin: 0px auto;
}

.title_org p{
    color: black;
    display: inline-block;
    padding: 10px;
}

.contenedor_org{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contenedor_org img{
    width: 100%;
    max-width: 200px;
    padding: 30px 50px;
}

@media only screen and (max-width: 768px){

    .title_org {
        width: auto;
    }


    .contenedor_org img {
        max-width: 90px;
        padding: 10px 15px;
    }
}