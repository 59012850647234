.sponsors{
    width: 100%;
    padding-top: 150px;
    position: relative;
    z-index: 100;
}

.sponsors_max{
    max-width: 1300px;
    margin: 0px auto;
  
}


.title_spon{
    text-align: left;
    padding: 50px 0 100px 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB2wAAAAGBAMAAAD9HHNBAAAAGFBMVEUpq+JHcEzZ4CHBJy3HVirBJy3Z4CEpq+KkpO+fAAAABXRSTlOAAICAOfY+veAAAABoSURBVFjD7djBCYAwDEbh9OTVihO4giNIByjoCt57cv3SOMEPOSi8b4SQByF27FBkNxtivPNcNiisXFBUX7PpQYzk87whIVvR6WtGblHaGOdKiGRLtmRLtuBI5kj+WLa8pHhJ8ZL6mw4Lra2H7exyWwAAAABJRU5ErkJggg==);
    background-size: 100% 1.2%;
    background-repeat: no-repeat;
    width: 20%;
    font-size: 35px;
}

.title_spon p{
    color: black;
    display: inline-block;
}

.contenedor_spon{
    display: flex;
    align-items: center;
    margin-left: 150px;
    height: 150px;

}

.contenedor_spon > div{
    color: #939393;
    border: 1px solid #939393;
    border-radius: 45px;
    padding: 10px 20px;
}

.contenedor_spon img{
    max-width: 400px;
    padding: 0px 0px 0px 60px;
}

.contenedor_spon a{
    width: 25%;
    text-align: center;
}

/* LOGOS */
.logo_alaya{
    width: 100%;
}

.logo_ey{
    width: 30%;
}

.logo_aws{
    width: 30%;
}

.logo_brownrudnick{
    width: 70%;
    transform: translate(0px, -5px);
}

.logo_microsoft{
    width: 85%;
}

.logo_salaberren{
    width: 100%;
}

.oikos{
    width: 50%;
}    

.cde_logo_negro{
    width: 70%;

}

.universidad_de_san_andres_logo{
    width: 50%;
}

.logo_embajada_israel{
    width: 100%;
}

@media only screen and (max-width: 768px){

    .title_spon {
        width: 100%;
    }

    .contenedor_spon {
        display: block;
        align-items: center;
        margin-left: 0px;
        text-align: center;
    }

    .title_spon p {
        display: block;
        text-align: center;
    }

    .contenedor_spon > div {
        width: 35%;
        margin: 0px auto;
        padding: 5px 15px;
        margin-bottom: 25px;

    }
    .contenedor_spon img {
        max-width: 400px;
        padding: 0px 0px 0px 25px;
    }

    .logo_alaya {
        width: 30%;
        padding: 0px 0px 0px 0px !important;
    }

    .logo_ey {
        width: 10%;
    }

    .logo_aws {
        width: 10%;
        padding: 0px 0px 0px 20px !important;
    }

    .logo_brownrudnick {
        width: 30%;
        transform: translate(0px, -8px);
        padding: 0px 0px 0px 20px !important;

    }

    .logo_microsoft {
        width: 35%;
        padding: 0px 0px 0px 20px !important;
        transform: translate(0px, -8px);
    }

    .logo_salaberren {
        width: 35%;
        padding: 0px 0px 0px 0px !important;

    }

    .oikos {
        width: 30%;
    }

    .cde_logo_negro{
        width: 20%;
    
    }
    
    .universidad_de_san_andres_logo{
        width: 20%;
    }

    .logo_embajada_israel{
        width: 40%;  
    transform: translate(0px, 5px);
    }

}