.agenda{
    position: relative;
    z-index: 100;
    padding: 50px;
    
  }

  .agenda_contenedor{
      text-align: center;
      margin: 0px auto;
  }
  
  .agenda_max{
    max-width: 1300px;
    margin: auto auto 50px;
  }

  .agenda_max hr{
    width: 30%;
    margin: 50px 0;
    background: #D9E021;
    border: 1px solid #D9E021;
  }
  
  /* Titulo de la sección y descripción */
  .titulo_agenda{
    width: 100%;
    margin-bottom: 60px;
  
  }
  
  .titulo_agenda div{
    text-align: justify;
    /* color: #707372; */
    font-weight: 600;
  }
  
  .contenedor_agenda{
    display: flex;
    justify-content: space-between;
  }
  
  .item_agenda1, .item_agenda3{
    width: 47%;
  }
  
  .item_agenda1 > p{
    display: inline-block;
    padding: 10px;
    border-left: 1px solid #D9E021;
    margin: 30px 0;
    margin-left: 45px;
  }
  
  
  .item_agenda3 > p{
    display: inline-block;
    padding: 10px;
    border-left: 1px solid #29ABE2;
    margin: 30px 0;
    margin-left: 45px;
  }
  
  
  /****************** Estilos generales ********************/
  .agenda .accor {
  
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Titulo */
  .agenda .accor .head {
    padding: 30px 20px;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 900;
    border: 2px solid #939393;  
    height: 180px;  
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

    /* TItulo sin body */
  .agenda .accor_2 .head {
    padding: 30px 20px;
    cursor: pointer;
    font-size: 20px;
    color: black;
    font-weight: 900;
    border: 2px solid #939393;  
    height: 180px;  
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tarde > .head {
    margin-bottom: 30px;
  }

  .head > p{
    margin: 0px 0px 35px 20px;
  }

  .head > p > span{
    font-size: 15px;
    display: inline-block;
    margin: 0px 0px 25px 0px;
  }

  .item_agenda1 span.private{
    font-size: 15px;
    margin-left: 30px !important;
    color: #D9E021;
  }

  .item_agenda3 span.private{
    font-size: 15px;
    margin-left: 30px !important;
    color: #29ABE2;
  }

  span.keynote{
    font-size: 15px;
    color: #C1272D;
    margin-left: 30px !important;
  }
  
  /* FLECHA */
  .agenda .accor .head img{
    width: 5%;
    max-height: 46px;
    transition: 0.4s;
    display: block;
    margin: 0px auto;
  }
  
  .agenda .accor.active .head > img{
    transform: rotate(180deg);
  }
  
  /* Body */
  .agenda .accor .body {
    color: black !important;
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: 600ms ease-in-out;
    transition: 600ms ease-in-out;

  }

  .body   b{
    cursor: pointer;
  }

  .item_agenda1 .body hr{
    width: 55%;
    margin: 30px 0;
    background: #D9E021;
    border: 1px solid #D9E021;
  }

 .body b{
    margin: 8px 0;
    display: inline-block;
  }
  
  .agenda .accor .body .conferencia{
    padding: 10px 40px;
  }
  
  
  .contenido_brief{
    display: block;
  }

  .contenido_brief b{
    font-family: 'BebasNeue-Regular';
    text-align: center;
    display: block;
    font-size: 25px;
  }
  
  .contenido_brief div{
    padding: 10px 40px;

  }
  
  .agenda .accor .body .imagen {
    text-align: center;
    font-size: 10px;
    margin-bottom: 15px;
  }
  .agenda .accor .body .imagen img {
    width: 50%;
    
  }
  
  .agenda .accor.active > .body {
    padding: 10px 20px;
    max-height: 600px;
    border: 1px solid #939393;    
    max-height: 400px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #EBEBEB;
  }

  .agenda .accor.active > .head {
    background: #EBEBEB;
  }

  
  
  /********************************** Estilos por cada Día ****************************/
/* 
.white > div{
  background: white;
}

.blue > div{
  background: #29ABE2;
}

.yellow > div{
  background: #D9E021;
}

.black > div{
  background: black;
} */
  
  
  /* RESPONSIVE */
  /* TABLET */
  @media only screen and (max-width: 768px){
    .titulo_agenda {
      display: block;
  }
  
  .agenda h2{
    text-align: center;
  }
  
  .titulo_agenda div {
    width: 80%;
    padding: 15px 0px;
    margin: 0px auto;
  }
  
  
    .contenedor_agenda {
      display: block;
  }
  
  .item_agenda1 {
    width: 100%;
    margin: 0px auto;
    margin-bottom: 10px;
  }
  
  .item_agenda2 {
    width: 100%;
    margin: 0px auto;
    margin-bottom: 10px;
  }
  
  .item_agenda3 {
    width: 100%;
    margin: 0px auto;
    margin-bottom: 10px;
  }
  }

@media only screen and (max-width: 500px){
  .agenda {
    position: relative;
    z-index: 1;
    padding: 35px;
}

.agenda .accor .head img {
  margin-top: 10px;
}

.head > p {
  margin: 0px;
}
.agenda .accor .head img {
  width: 13%;
}

}