/* ESTILOS BANNER */

.banner{
    height: 85vh;
    position: relative;
    z-index: 100 !important;
}

.typeform-widget{
    width: 100%;
    height: 500px;
}

.flecha_scroll{
    text-align: center;
    margin: 0px auto;
    display: block;
    animation: scroll 6s 2;
    width: 50px;
    cursor: pointer;
}

.texto_scroll{
    text-align: center;
    margin: 0px auto;
    display: block;
    font-size: 18px;
    color: #29ABE2;
    animation: color 6s 2;
    margin-top: 10px;
}

@keyframes scroll {
    0% { transform: translate(0px, -10px);
      }
    25% {
        transform: translate(0px, 0px);
     }
    50% { 
        transform: translate(0px, -10px);
     }
    75% { 
        transform: translate(0px, 0px);
     }
    100% { 
        transform: translate(0px, -10px);
     }
  }

  @keyframes color {
    0% {opacity: 0.1; }
    25% { opacity: 1; }
    50% {opacity: 0.1; }
    75% { opacity: 1; }
    100% {opacity: 0.1;  }

  }

/* CONTENEDOR */
.contenedor_banner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.fics_3d{
    background: url('../../../assets/img/logo-fics.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 30vh;
    width: 40%;
    height: 55%;
}

.boton{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
    height: 15%;
    cursor: pointer;
    color: #C1272D;
    margin-top: 30vh;
}

.registro_boton{
    border: 1px solid #C1272D;
    width: 20%;
    border-radius: 25px;
    padding: 15px 50px;
    display: flex;
    justify-content: space-around;
    transition: 0.5s;
}

.registro_boton:hover{
    background:  #C1272D;
    color: #F2F2F2 !important;
}

.registro_boton a{
    text-decoration: none;
    color: black;
}

.registro_boton a:hover{
    text-decoration: none;
    color: white;
}

.modal_registro{
    top: 100px;
    width: 90%;
    height: 80vh;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB2wAAAAGBAMAAAD9HHNBAAAAGFBMVEUpq+JHcEzZ4CHBJy3HVirBJy3Z4CEpq+KkpO+fAAAABXRSTlOAAICAOfY+veAAAABoSURBVFjD7djBCYAwDEbh9OTVihO4giNIByjoCt57cv3SOMEPOSi8b4SQByF27FBkNxtivPNcNiisXFBUX7PpQYzk87whIVvR6WtGblHaGOdKiGRLtmRLtuBI5kj+WLa8pHhJ8ZL6mw4Lra2H7exyWwAAAABJRU5ErkJggg==), white;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    transition: 1s;
    opacity: 0;
    visibility: hidden;
}

.modal_registro .cerrar_div{
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;
    text-align: right;
}

.modal_registro .cerrar_div img{
   width: 25%;
   cursor: pointer;
}

.modal_registro_ingles{
    top: 100px;
    width: 90%;
    height: 80vh;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB2wAAAAGBAMAAAD9HHNBAAAAGFBMVEUpq+JHcEzZ4CHBJy3HVirBJy3Z4CEpq+KkpO+fAAAABXRSTlOAAICAOfY+veAAAABoSURBVFjD7djBCYAwDEbh9OTVihO4giNIByjoCt57cv3SOMEPOSi8b4SQByF27FBkNxtivPNcNiisXFBUX7PpQYzk87whIVvR6WtGblHaGOdKiGRLtmRLtuBI5kj+WLa8pHhJ8ZL6mw4Lra2H7exyWwAAAABJRU5ErkJggg==), white;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    transition: 1s;
    opacity: 0;
    visibility: hidden;
}

.modal_registro_ingles .cerrar_div{
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;
    text-align: right;
}

.modal_registro_ingles .cerrar_div img{
   width: 25%;
   cursor: pointer;
}

.show_modal_registro{
    opacity: 1 !important;
    visibility: visible !important;
}

.fadeOut{
    opacity: 0;
    visibility: hidden;
}

@media only screen and (max-width: 768px){
    .contenedor_banner {
      flex-direction: column;
    }

    .fics_3d {
        margin: 75px 0;
        width: 70%;
        height: 15%;
    }

    .boton {
        width: 70%;
        height: 30%;
        margin-top: 0vh;
    }
}

@media only screen and (max-width: 500px){
    .modal_registro {
        width: 100%;
        height: 84vh;
    }

    .modal_registro_ingles {
        width: 100%;
        height: 84vh;
    }

}