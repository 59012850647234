.acerca{
    margin: 120px 50px;
    color: black;
    position: relative;
    z-index: 100 !important;
}

.acerca_max{
    max-width: 1300px;
    margin: 0px auto;
    text-align: justify;
}

.acerca_max hr{
    width: 30%;
    background: #29ABE2;
    border: 1px solid #29ABE2;
  }

.acerca_contenedor{
    display: flex;
    /* margin: 0px auto; */
}

.acerca_info{
    width: 50%;
    font-size: 40px;
}

.acerca_info img{
    display: block;
    width: 180px;
    margin: 30px 0px;
}

.acerca_contenedor .texto_acerca{
    width: 50%;
    margin-top: 195px;
}

.acerca_contenedor .texto_acerca h2{
    font-size: 60px;
}


.acerca_contenedor .texto_acerca span{
    color: #D9E021;
}

.acerca_contenedor .texto_acerca p{
 
}

.acerca_contenedor .texto_acerca img{
    width: 70%;
}

@media only screen and (max-width: 768px){
    .acerca_contenedor {
        display: block;
    }

    .acerca_info {
        width: 100%;
        font-size: 60px;
    }

    .acerca_contenedor .texto_acerca {
        width: 100%;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 500px){
    .acerca {
        padding: 120px 0px 0px 0px;
        margin: 120px 30px;
    }

    .acerca_info {
        width: 100%;
        font-size: 40px;
    }
}


@media only screen and (max-width: 360px){
    .acerca_info {
        width: 100%;
        font-size: 30px;
    }
}