.speakers{
    padding: 25px;
    max-width: 1300px;
    margin: 0px auto;
    position: relative;
    z-index: 100;
}

.speakers h2{
    width: auto;
    padding: 50px;
}

.contenedor_speakers{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.speaker{
    background: white;
    margin:  15px 15px;
    width: 90%;
}

.speaker:hover{
    filter: brightness(0.9);
}

/* BACKGROUND DE CADA SPEAKER */
/* PRIMERA COLUMNA */
.imagen_div{
    filter: grayscale(1);
}
#speaker_1, #modal_1 .imagen_div{
    background: url('../../../assets/img/speakers/Alejandro_sorgentini.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_2, #modal_2 .imagen_div{
    background: url('../../../assets/img/speakers/Andres_Meirovich.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_3, #modal_3 .imagen_div{
    background: url('../../../assets/img/speakers/Ines_bonicelli.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_4, #modal_4 .imagen_div{
    background: url('../../../assets/img/speakers/Luis-Felipe-Oliva.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

/* SEGUNDA COLUMNA */
#speaker_5, #modal_5 .imagen_div{
    background: url('../../../assets/img/speakers/Martin_Benitez.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_6, #modal_6 .imagen_div{
    background: url('../../../assets/img/speakers/ramiro_lauzarn.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_7, #modal_7 .imagen_div{
    background: url('../../../assets/img/speakers/JB_foto.jpg');

    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_8, #modal_8 .imagen_div{
    background: url('../../../assets/img/speakers/Roberto_chute_edited.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
/* TERCERA COLUMNA */
#speaker_9, #modal_9 .imagen_div{
    background: url('../../../assets/img/speakers/josx-sosa-del-valle_1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_10, #modal_10 .imagen_div{
    background: url('../../../assets/img/speakers/DGB.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 40%;
}

#speaker_11, #modal_11 .imagen_div{
    background: url('../../../assets/img/speakers/Isabella_antonaccio.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_12, #modal_12 .imagen_div{
    background: url('../../../assets/img/speakers/yigal_erlich.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

/* CUARTA COLUMNA */
#speaker_13, #modal_13 .imagen_div{
    background: url('../../../assets/img/speakers/Antonia_Rojas.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_14, #modal_14 .imagen_div{
    background: url('../../../assets/img/speakers/luis_bernajo.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_15, #modal_15 .imagen_div{
    background: url('../../../assets/img/speakers/valen.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_16, #modal_16 .imagen_div{
    background: url('../../../assets/img/speakers/Isabelle_chaquiriand.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

/* QUINTA COLUMNA */
#speaker_17, #modal_17 .imagen_div{
    background: url('../../../assets/img/speakers/ManuelSilva.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_18, #modal_18 .imagen_div{
    background: url('../../../assets/img/speakers/agustin.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_19, #modal_19 .imagen_div{
    background: url('../../../assets/img/speakers/stephen.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_20, #modal_20 .imagen_div{
    background: url('../../../assets/img/speakers/Sergio_fogel.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

/* SEXTO COLUMNA */
#speaker_21, #modal_21 .imagen_div{
    background: url('../../../assets/img/speakers/hernan.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_22, #modal_22 .imagen_div{
    background: url('../../../assets/img/speakers/tinelli.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 40%;
}

#speaker_23, #modal_23 .imagen_div{
    background: url('../../../assets/img/speakers/EugenioPace.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    /* background-position: 50% 40%; */
}

#speaker_24, #modal_24 .imagen_div{
    background: url('../../../assets/img/speakers/Ricardo_donoso.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_25, #modal_25 .imagen_div{
    background: url('../../../assets/img/speakers/Mariano_amartino.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_26, #modal_26 .imagen_div{
    background: url('../../../assets/img/speakers/dittborn_julio.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_27, #modal_27 .imagen_div{
    background: url('../../../assets/img/speakers/Colin_Curey.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_28, #modal_28 .imagen_div{
    background: url('../../../assets/img/speakers/macarena.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_29, #modal_29 .imagen_div{
    background: url('../../../assets/img/speakers/Embajador_Arguello.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_30, #modal_30 .imagen_div{
    background: url('../../../assets/img/speakers/Ivonne_cuello.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_31, #modal_31 .imagen_div{
    background: url('../../../assets/img/speakers/sarah.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_32, #modal_32 .imagen_div{
    background: url('../../../assets/img/speakers/Eduardo_magarelli.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 15%;
}

#speaker_33, #modal_33 .imagen_div{
    background: url('../../../assets/img/speakers/Eric_manlunas.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 50%;
}

#speaker_34, #modal_34 .imagen_div{
    background: url('../../../assets/img/speakers/Maria_Angeles_Romo.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    /* background-position: 50% 50%; */
}

#speaker_35, #modal_35 .imagen_div{
    background: url('../../../assets/img/speakers/patricia-pastor-investor.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-position: 50% 50%;
}

#speaker_36, #modal_36 .imagen_div{
    background: url('../../../assets/img/speakers/carmen.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_37, #modal_37 .imagen_div{
    background: url('../../../assets/img/speakers/Saul_singer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_38, #modal_38 .imagen_div{
    background: url('../../../assets/img/speakers/Amat_alfonso.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_39, #modal_39 .imagen_div{
    background: url('../../../assets/img/speakers/alejandro_mashad.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_40, #modal_40 .imagen_div{
    background: url('../../../assets/img/speakers/meghan_stevenson.JPG');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}


#speaker_42, #modal_42 .imagen_div{
    background: url('../../../assets/img/speakers/francisco-guzman.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_43, #modal_43 .imagen_div{
    background: url('../../../assets/img/speakers/Andres-Ackermann.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_44, #modal_44 .imagen_div{
    background: url('../../../assets/img/speakers/ignacio_hec.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 25%;
}

#speaker_45, #modal_45 .imagen_div{
    background: url('../../../assets/img/speakers/javier_herrera.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_49, #modal_49 .imagen_div{
    background: url('../../../assets/img/speakers/paimun.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}



#speaker_47, #modal_47 .imagen_div{
    background: url('../../../assets/img/speakers/yoed.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_50, #modal_50 .imagen_div{
    background: url('../../../assets/img/speakers/matias_peire.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_51, #modal_51 .imagen_div{
    background: url('../../../assets/img/speakers/Rodrigo_villar.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

#speaker_52, #modal_52 .imagen_div{
    background: url('../../../assets/img/speakers/Quin_garcia.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}


.none{
    background: transparent !important;
    border: none;
}

.imagen_speaker{
    position: relative;
    height: 260px;
    cursor: pointer;
    transition: 1s;
    filter: grayscale(1);
}

.imagen_speaker:hover{
    filter: brightness(0.7);
    filter: grayscale(1);

}

.imagen_speaker:hover > .texto_hover{
    opacity: 1;
    bottom: 0px;
}


.contenedor_speakers .imagen_speaker img{
    width: 100%;
    cursor: pointer;
}


.texto_hover{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #dae0217a;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    opacity: 0;
    transition: 0.5s;
    bottom: -10px;
    z-index: 10;
}

.texto_hover a{
    display: block;
    width: 20% !important;
    margin: 5px;
    height: 40px;
    max-width: 50px;
}

a.linkedIn{
    background: url('../../../assets/img/speakers/logo-linkedin-speaker.png');
    background-size: contain;
    background-repeat: no-repeat;
}

a.linkedIn:hover{
    background: url('../../../assets/img/speakers/hover-logo-linkedin-speaker.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.texto_hover img{
    width: 100% !important;
}

.texto_speaker{
    padding: 30px 20px 20px 30px;
}

.texto_speaker .keynote{
    margin-left: 0px !important;
    font-size: 13px;
}


/* MODALS */

.modal_content{
    background: rgba(0, 0, 0, 0.603);
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.modal_content .modal{
    width: 65%;
    margin: 0px auto;
    background: white;
    transition: 0.5s;
    position: relative;
}

.show_modal{
    visibility: visible;
    opacity: 1;
}

.show_modal > .modal{
    margin-top: 20vh;
}

.no_scroll{
    overflow-y: hidden;
}


.modal .modal_contenedor{
    display: flex;
    height: 400px;

}

.modal_contenedor .imagen{
    padding: 20px 30px;
    width: 50%;
}

.modal_contenedor .imagen hr{
    width: 250px;
    margin: 0px auto;
    background: #D9E021;
    border: 1px solid #D9E021;
}


.modal_contenedor .imagen .imagen_div{
    width: 280px;
    height: 280px;
    transform: translate(0px, -50px);
    margin: 0px auto;
    display: block;
}

.modal_contenedor .redes{
    width: 100%;
}

.redes a.linkedIn {
    background: url('../../../assets/img/speakers/linkedin-icono.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 45px;
    height: 100px;
    display: block;
    margin: 0px auto;
    margin-top: 25px;
    transition: 0.5s;
    transform: translateY(-15px);
}

.redes a.linkedIn:hover {
    background: url('../../../assets/img/speakers/hover-linkedin-icono.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 10%;
    height: 100px;
    display: block;
    margin: 0px auto;
    margin-top: 25px;
}


.modal_contenedor .contenido{
    width: 50%;
    padding: 30px 30px 40px 0;
    overflow: scroll;
    overflow-x: hidden;
}

.modal_contenedor .contenido b {
    font-size: 25px;
    margin-bottom: 15px;
    display: inline-block;
}

.modal_contenedor .contenido  p {
    margin-bottom: 15px;
}


.contenido > div{
    text-align: right;
}

.modal_contenedor .contenido div > img{
    width: 25px;
    transform: translate(-10px, -15px);
    cursor: pointer;
    position: fixed;
}

@media only screen and (max-width: 768px){
    .speakers {
        height: 7760px;
    }

    .contenedor_speakers {
        display: block;
    }
    
    .speaker {
        float: left;
        width: 42%;
        
    }
}

@media only screen and (max-width: 500px){
    .speaker {
        float: left;
        width: calc(50% - 12px);
        margin: 15px 5px 20px;
        margin-bottom: 20px;
    }

    /* MODALS */

    .modal_content {
        height: 100%;
    }

    .modal_content .modal {
        width: 100%;
        margin-top: 0;
        margin: 0px auto;
        height: 100%;
        overflow: scroll;

        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAB2wAAAAGBAMAAAD9HHNBAAAAGFBMVEUpq+JHcEzZ4CHBJy3HVirBJy3Z4CEpq+KkpO+fAAAABXRSTlOAAICAOfY+veAAAABoSURBVFjD7djBCYAwDEbh9OTVihO4giNIByjoCt57cv3SOMEPOSi8b4SQByF27FBkNxtivPNcNiisXFBUX7PpQYzk87whIVvR6WtGblHaGOdKiGRLtmRLtuBI5kj+WLa8pHhJ8ZL6mw4Lra2H7exyWwAAAABJRU5ErkJggg==), white;
        background-size: 100% 0.3%;
        background-repeat: no-repeat;
    }


    .modal .modal_contenedor {
        display: block;
    }

    .texto_speaker {
        padding: 15px 15px 15px;
        height: 60px;    
        font-size: 12px;
    }

    .modal_contenedor .imagen {
        padding: 100px 0px 30px 0px;
        width: 90%;
        text-align: center;
        margin: 0px auto;
    }

    .modal_contenedor .imagen img {
        transform: translate(0px, 0px);
        max-height: 215px;
    }

    .imagen_speaker {
        height: calc(50vw - 35px);
        width: calc(50vw - 35px);
        background-size: cover !important;
        filter: grayscale(1);
    }
    

    .modal_contenedor .contenido {
        width: 90%;
        margin: 0px auto;
        padding: 20px 0px 40px 0;
    }

    .modal_contenedor .contenido div > img {
        transform: translate(-18px, -2065%);
    }

    
    .texto_hover {
        opacity: 1;
        bottom: -1px;
    }

    .texto_hover a {
        width: 15% !important;
        margin: 5px;
        height: 20px;
    }

    .title_org {
        padding: 0px 0 50px 0;
    }

}

@media only screen and (max-width: 360px){
    .speakers {
        height: auto;
    }
    
    .speaker {
        float: none;
        width: 90%;
    }

    .imagen_speaker {
        height: 260px;
        width: auto;
    }
}