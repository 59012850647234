/* header{ 
    background: transparent;
    width: 100%;
    position: relative;
} */

.barra_tricolor{
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.menu_contenedor{
    display: flex;
    position: fixed;
    align-items: center;
    width: 100%;
    height: 100px;
    background: #ebebeb94;
    backdrop-filter: blur(6px);
-webkit-backdrop-filter: blur(6px);
z-index: 1000;
}

.menu_contenedor .logos{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    color: black;
}

.logos .idioma_choose{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    border-radius: 45px;
    padding: 3px 0px;
    width: 30%;
    cursor: default;
    font-size: 11px;
}

.flecha_idioma{
    font-size: 11px;
    font-weight: 600;
    transform: rotate(180deg);
    cursor: pointer;
    transition: 0.2s;
}

.logos p{
    cursor: pointer;
}

.menu_contenedor .logos img{
    width: 130px;
    max-width: 150px;
    margin-right: 50px;
    cursor: pointer;

}

.menu_contenedor .menu{
    width: 75%;
    text-align: right;
}

.menu_contenedor .menu ul li{
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    color: black;
    font-weight: 600;
    font-size: 16px;
    transition: 0.5s;
}

.menu_contenedor .menu ul a{
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    color: black;
    font-weight: 600;
    font-size: 16px;
    transition: 0.5s;
    text-decoration: none;
}

.menu_contenedor .menu ul li:hover{
    color: #939393 !important;   
}

.menu_contenedor .menu ul a:hover{
    color: #939393 !important;   
}

.registro{
    border: 1px solid #C1272D;
    border-radius: 45px;
    padding: 5px 10px;
}

.menu_idioma{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 7%;
    font-size: 13px;
    transform: translate(75%, 90%);
    padding: 10px 0;
    border-radius: 0 0 20px 20px;
    opacity: 0;
    transition: 0.5s;
    z-index: 1;
    background: rgba(255, 255, 255, 0.89);
    text-align: center;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.menu_idioma .item_idioma{
    color: black;
    text-decoration: none;
    padding: 5px 0;
}
.turn_flecha{
    transform: rotate(0deg);
}

.show_idioma{
    transform: translate(75%, 155%);
    opacity: 1;
}


@media only screen and (max-width: 1024px){
    .menu_contenedor .menu ul li {
        display: inline-block;
        margin: 5px 10px;
        font-size: 9px;
    }
    
}

@media only screen and (max-width: 768px){
    .menu_contenedor .logos {
        width: 100%;
    }

    .menu_contenedor .logos img {
        margin-right: 10%;
    }

    .menu {
        position: fixed;
        width: 100%;
        text-align: center;
        height: 100%;
        right: 0;
        background: rgba(0, 0, 0, 0.603);
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
    }

    .menu_show{
        opacity: 1;
        visibility: visible;
    }

    .menu ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .menu ul li{
        display: inline-block;
        color: white;
        margin: 10px 0;
    }

    .hamburguesa img{
        width: 30%;
    }

    .home{
        color: #D9E021 !important;
    }

    .menu_idioma{
        transform: translate(0%, 100%);
        left: 64%;
    }

    
    .show_idioma {
        transform: translate(0%, 115%);
        left: 64%;
        opacity: 1;
    }
}

@media only screen and (max-width: 500px){
    .menu_idioma {
        width: 25%;
        left: 45%;
        transform: translate(0%, 140%);
    }

    .logos .idioma_choose {
        padding: 5px 5px;
    }

    .show_idioma {
        transform: translate(0%, 155%);
        left: 45%;
        opacity: 1;
    }
}