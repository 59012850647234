*{
  padding: 0;
  margin: 0;
}

.fondo_puntos {
  background: url('./assets/img/puntos_fondo.png'), #f2f2f2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 0;
}

.spanish > div{
	position: relative;
    z-index: 2000;
}

.ingles > div{
	position: relative;
    z-index: 2000;
}



/* FONTS */

@font-face {
	font-family: 'Calibri Italic';
	src: url('./assets/fonts/Calibri Italic.eot');
	src: local('☺'), url('./assets/fonts/Calibri Italic.woff') format('woff'), url('./assets/fonts/Calibri Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri Bold';
	src: url('./assets/fonts/Calibri Bold.eot');
	src: local('☺'), url('./assets/fonts/Calibri Bold.woff') format('woff'), url('./assets/fonts/Calibri Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Calibri';
	src: url('./assets/fonts/Calibri Regular.eot');
	src: local('☺'), url('./assets/fonts/Calibri Regular.woff') format('woff'), url('./assets/fonts/Calibri Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

p{
	font-family: 'Calibri';
}

h2{
	font-family: 'Calibri';
    font-weight: 300;
    font-size: 40px;
}

.desktop{
	display: block;
}

.movil{
	display: none;
}


@media only screen and (max-width: 768px){

	
.fondo_puntos {
	background:  #f2f2f2;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 1;
  }


	.desktop{
		display: none !important;
	}
	
	.movil{
		display: block !important;
	}

	.z_index{
		z-index: 10 !important;
	}

}